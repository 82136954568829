<ng-container *transloco="let t; scope: 'core-components'; read: 'coreComponents.dateRangeFilter'">
    <dsh-filter
        [active]="isActive"
        [activeLabel]="activeLabel$ | async"
        [content]="content"
        [label]="t('label')"
        (clear)="clear()"
        (save)="save()"
    >
    </dsh-filter>
    <ng-template #content>
        <div *ngIf="step === stepEnum.Presets" fxLayout="column" fxLayoutGap="24px">
            <div
                *ngFor="let preset of presets"
                [ngClass]="{ 'dsh-date-range-filter-preset-active': preset === value.preset }"
                class="dsh-subheading-2 preset"
                (click)="selectPreset(preset)"
            >
                {{ (presetLabels$ | async)?.[preset] }}
            </div>
        </div>
        <mat-calendar
            *ngIf="step === stepEnum.Calendar"
            [maxDate]="maxDate"
            [selected]="value.dateRange"
            (selectedChange)="selectedChange($event)"
        ></mat-calendar>
    </ng-template>
</ng-container>
